import course2_card_image from "../components/ProgramCatalog/assets/microcontroller-based-embedded-system-design.webp";
import ml_course_image from "../components/ProgramCatalog/assets/embedded-machine-learning-for-edge-computing.webp";
import systemVerilog_course_image from "../components/ProgramCatalog/assets/system-verilog-for-asic-fpga-design-and-simulation.webp";
import cybersecurity_image from "../components/Home/assets/cybersecurity.webp";
import fdsdCoureImage from "../components/ProgramCatalog/assets/fundamentals-of-digitla-system-design.webp";
import iot_course_image from "../components/ProgramCatalog/assets/embedded-product-design-for-iot.webp";
import quantum_computing_course_image from "../components/ProgramCatalog/assets/introduction-to-quantum-computing.webp";
import intro_to_gen_ai from "../components/ProgramCatalog/assets/IntroductiontoGenerativeAI.webp";

export function cardData() {
  const data = [
    {
      cardImage: iot_course_image,
      cardImageAlt:
        "Image representing Embedded Product Design for IoT short course",
      title: "Embedded Product Design for IoT",
      type: "Short Course",
      difficulty: "Intermediate",
      description:
        "Building on top of microcontroller programming skills, learn how to develop embedded product prototypes for IoT applications.",
      ratingCount: 20,
      starCount: 4.5,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      isLineBreak: true,
      prereq:
        "Basic knowledge of microcontroller programming. Knowledge of topics covered in the Microcontroller-Based Embedded System Design course will be a plus.",
      actionDownload: "/",
      actionProgramDetails: "/embedded-product-design",
      IsSpecialization: true,
      specialization: "Internet-of-Things",
      isNewCourse: true,
      duration: "8 Weeks",
      isOneDayWorkShop: false,
      isOverlay: false,
      buttonText: "Program Details",
      currentState: "Enroll Now",
    },
    {
      cardImage: systemVerilog_course_image,
      cardImageAlt:
        "Image representing {System}Verilog for ASIC/FPGA Design & Simulation short course",
      title: "{System}Verilog for ASIC/FPGA Design & Simulation",
      type: "Short Course",
      difficulty: "Beginner",
      description:
        "The importance of processor design becomes evident when we consider the rapid pace of technological advancement in the field of computing. Get comprehensive training on {System}Verilog for ASIC/FPGA Design & Simulation throughout 8 weeks.",
      ratingCount: 30,
      starCount: 5,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      isLineBreak: true,
      prereq: "Basic knowledge of programming in any language.",
      actionDownload: "/",
      actionProgramDetails: "/verilog-for-ASIC-FPGA-design-simulation",
      IsSpecialization: true,
      specialization: "Build Your Own CPU",
      isNewCourse: true,
      duration: "8 Weeks",
      buttonText: "Program Details",
      currentState: "Enroll Now",
    },
    {
      cardImage: fdsdCoureImage,
      cardImageAlt:
        "Image representing Fundamentals of Digital System Design",
      title: "Fundamentals of Digital System Design",
      type: "Short Course",
      difficulty: "Intermediate",
      isLineBreak: false,
      description:
        "Explore the essential principles of Digital System Design with Skill Surf’s comprehensive course. This program melds foundational theories with hands-on applications, equipping you to design and optimize digital systems effectively. Delve into circuit design, logic, and system architecture to build robust digital solutions that are crucial for today’s technology-driven landscapes.",
      ratingCount: 0,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      prereq:
        "The course does not require any prior experience in processor design. As long as you have a background in computer programming in any language, we will provide you with the necessary ingredients to get started in this domain and become an expert.",
      actionDownload: "/",
      actionProgramDetails: "/fundamentals-of-digital-system-design",
      starCount: 0,
      duration: "8 Weeks",
      IsSpecialization: true,
      specialization: "Build Your Own CPU",
      buttonText: "Program Details",
      currentState: "Classes Ongoing",
    },
    {
      cardImage: ml_course_image,
      cardImageAlt:
        "Image representing Embedded Machine Learning for Edge Computing short course",
      title: "Embedded Machine Learning for Edge Computing",
      type: "Short Course",
      difficulty: "Intermediate",
      isLineBreak: false,
      description:
        "Embedded Machine Learning for Edge Computing is deploying Machine Learning (ML) applications in devices throughout the physical world. Get comprehensive training to develop and deploy real-world ML applications on embedded systems.",
      ratingCount: 50,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      prereq:
        "Basic knowledge of programming in any language. Microcontroller programming knowledge will be a plus but not mandatory.",
      actionDownload: "/",
      actionProgramDetails: "/embedded-machine-learning-for-edge-computing",
      starCount: 4.4,
      duration: "8 Weeks",
      IsSpecialization: true,
      specialization: "Internet-of-Things",
      buttonText: "Program Details",
      currentState: "Registration Closed",
    },
    {
      cardImage: intro_to_gen_ai,
      cardImageAlt:
        " Introduction to Generative AI",
      title: " Introduction to Generative AI ",
      type: "Workshop",
      difficulty: "Beginner",
      description:
        "Dive into the transformative world of Generative AI in this one-day session designed to unlock the fundamentals and unleash your creativity. With the rising demand and future potential of Generative AI in the industry, ENTC, together with IEEE Sri Lanka Section and Skill Surf, aims to train engineers in this area. “Introduction to Generative AI” is a one-day workshop open to everyone offered free of charge.",
      ratingCount: 0,
      starCount: 0,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      isLineBreak: true,
      prereq: "Basic Python programming",
      actionDownload: "/",
      actionProgramDetails: "/introduction-to-generative-ai",
      duration: "1 Day",
      isOneDayWorkShop: false,
      isOverlay: true,
      overlayText: "Free",
      buttonText: "Program Details",
      isNewWorkshop: true,
      currentState: "Registration Closed",
    },
    {
      isLineBreak: true,
      cardImage: course2_card_image,
      cardImageAlt:
        "Image representing Microcontroller-Based Embedded System Design short course",
      title: "Microcontroller-Based Embedded System Designs '24 (Batch 03)",
      type: "Short Course",
      difficulty: "Beginner",
      description:
        "Explore the intricacies of Microcontroller-Based Embedded System Designs in Skill Surf’s short course, blending theory and hands-on experience for a tech-forward skill set.",
      ratingCount: 50,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Skill Surf",
      prereq:
        "Anyone willing to get started with microcontroller-based embedded system designs can join and no prior experience is required.",
      actionDownload: "/",
      actionProgramDetails: "/microcontroller-based-embedded-system-design",
      starCount: 4.8,
      duration: "8 Weeks",
      IsSpecialization: true,
      specialization: "Internet-of-Things",
      buttonText: "Program Details",
      currentState: "Registration Closed",
      isNewIntake:true,
      newIntakeText:"Third Intake"
      
      
    },
    {
      cardImage: cybersecurity_image,
      cardImageAlt:
        "Image representing Cryptography Fundamentals: Securing the Digital World short course",
      title: "Cryptography Fundamentals: Securing the Digital World",
      type: "Short Course",
      difficulty: "Beginner",
      description:
        "Build a rock-solid foundation: master cryptography fundamentals with hands-on experience to safeguard the digital world",
      ratingCount: 0,
      starCount: 0,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      isLineBreak: true,
      prereq:
        "No prior experience in cryptography is required. Anyone with basic programming knowledge can take part.",
      actionDownload: "/",
      actionProgramDetails:
        "/cryptography-fundamentals-securing-the-digital-world",
      IsSpecialization: true,
      specialization: "Cybersecurity",
      isNewCourse: true,
      duration: "8 Weeks",
      isOneDayWorkShop: false,
      isOverlay: false,
      buttonText: "Program Details",

      currentState: "Registration Closed",
      courseFee: "22,500",
      discount: "60%",
    },
    
   
    

   
    {
      cardImage: quantum_computing_course_image,
      cardImageAlt:
        "Image representing Introduction to Quantum Computing short course",
      title: "Introduction to Quantum Computing",
      type: "Workshop",
      difficulty: "Beginner",
      description:
        "Look beyond the world of classical computers. Understand the “why” and “how” behind a cutting-edge domain with many interesting applications. Let’s start exploring the world of quantum computing!",
      ratingCount: 0,
      starCount: 0,
      learning: "Machine Learning, Edge Computing, Embedded Systems",
      conductedBy:
        "Department of Electronic & Telecommunication Engineering, University of Moratuwa, Sri Lanka",
      isLineBreak: true,
      prereq: "No prior experience in Quantum Computing is required.",
      actionDownload: "/",
      actionProgramDetails: "/introduction-to-quantum-computing",
      duration: "1 Day",
      isOneDayWorkShop: false,
      isOverlay: true,
      overlayText: "Free",
      buttonText: "Program Details",
      isNewWorkshop: true,
      currentState: "Registrations Closed",
    },
  ];

  return data;
}
