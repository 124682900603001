import EmbeddedProductDesignForIotImage from "../../components/ProgramCatalog/assets/embedded-product-design-for-iot.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
//import pasinduSandima from "../../components/CoursePage/assets/instructor-img/Pasindu-Sandima.webp";
import chathuniWijegunawardena from "../../components/CoursePage/assets/instructor-img/Chathuni Wijegunawardana.webp";
//import sanjulaThiranjaya from "../../components/CoursePage/assets/instructor-img/Sanjula-Thiranjaya.webp";
import pahanMendis from "../../components/CoursePage/assets/instructor-img/Pahan Mendis.webp";
import thamiduNaveen from "../../components/CoursePage/assets/instructor-img/Thamidu_Naveen.webp";
import kithminWickremasingheImageTwo from "../../components/CoursePage/assets/instructor-img/Kithmin.webp";
//import sachiniWickramasinghe from "../../components/CoursePage/assets/instructor-img/Sachini Wickramasinghe.webp";
//import savinduDimal from "../../components/CoursePage/assets/instructor-img/Savindu Dimal.webp";
import devnithw from "../../components/CoursePage/assets/instructor-img/DevnithWijesinghe.jpg";
import yasiths from "../../components/CoursePage/assets/instructor-img/YasithSilva.jpg";
import ruchira from "../../components/CoursePage/assets/instructor-img/ruchira.png";
import kosalaJayasundara from "../../components/CoursePage/assets/instructor-img/Kosala Jayasundara.webp";
import productDesignFlyer from "../../components/CoursePage/assets/Embedded-Product-Design-for-IoT-design.webp";
import { ECourseStatus, ICourse } from "../utils/utils";

export const embeddedProductDesignForIot: ICourse = {
  header: {
    courseImage: EmbeddedProductDesignForIotImage,
    programCategory: "Short Course",
    difficultyLevel: "Intermediate",
    courseTitle: "Embedded Product Design for IoT",
    specialization: "Internet of Things",
    courseDescription:
      "Embedded product design is critical for IoT as it enables the creation of smart and connected devices that are efficient, reliable, secure, and tailored to the specific needs of different applications and industries. Get comprehensive training on Embedded Product Design in 8 sessions.",
    registerButton: "View Instructions for Next Steps",
    registerLink: "https://drive.google.com/file/d/163yWpV4tvrUMvMkdyq2-JHHDMyD9l0yd/view?usp=drive_link",
    secondaryName: "",
  },
  headerSecondSection: {
    starCount: 4.5,
    reviewCount: 20,
    registrationDeadline: "28 May 2023",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "New Course",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading:
            "Introduction to product design for IoT and rapid prototyping",
          description: "",
          subContent: [],
        },
        {
          heading: "Feasibility analysis  - what is a conceptual model?",
          description: "",
          subContent: [],
        },
        {
          heading:
            "IoT based project overview - specification and component selection",
          description: "",
          subContent: [],
        },
        {
          heading: "Advanced schematic and PCB layout design",
          description: "",
          subContent: [],
        },
        {
          heading: "Embedded firmware development",
          description: "",
          subContent: [],
        },
        {
          heading: "Enclosure design for IoT products",
          description: "",
          subContent: [],
        },
        {
          heading: "End to end connectivity for web dashboard",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Enclosure and PCB manufacturing techniques and best practices",
          description: "",
          subContent: [],
        },
        {
          heading: "Soldering, testing and firmware deployment",
          description: "",
          subContent: [],
        },
        {
          heading: "Putting it all together to complete the IoT product",
          description: "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of Embedded Product Design. Will I be able to do this course?",
      answer: `Yes. We teach all the required skills. The only expectation is that you have done basic microcontroller programming (e.g., Arduino). Knowledge of topics covered in the <a href='https://www.skillsurf.lk/microcontroller-based-embedded-system-design' target='_blank' rel='noreferrer'>Microcontroller-Based Embedded System Design</a> course will be a plus.`,
    },
    {
      question:
        "Do I need electronic equipment/software licenses to complete this course?",
      answer:
        "Yes. You will need hardware to complete the course. We will facilitate the purchasing process during the course.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to develop IoT products.",
    },
    {
      question: "Is the course taught online?",
      answer:
        "Yes. The course lectures are all conducted online. We will arrange hands-on sessions at ENTC laboratories as required.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system called Moodle. All paid participants will be granted access to the course Moodle page.",
    },
  ],
  experts: [
    {
      image: kithminWickremasingheImageTwo,
      name: "Kithmin Wickremasinghe",
      designation: "MASc, Electrical and Computer Eng., UBC, Canada",
    },
    
    
    {
      image: thamiduNaveen,
      name: "Thamidu Naveen",
      designation: "PhD candidate at RMIT, Australia",
    },
    {
      image: pahanMendis,
      name: "Pahan Mendis",
      designation: "Electronic Engineer, Paraqum Technologies",
    },
    {
      image: chathuniWijegunawardena,
      name: "Chathuni Wijegunawardena",
      designation: "Electronic Engineer, Paraqum Technologies",
    },
    {
      image: devnithw,
      name: "Devnith Wijesinghe",
      designation: "3rd Year Undergraduate, ENTC",
    },
    {
      image: yasiths,
      name: "Yasith Silva",
      designation: "3rd Year Undergraduate, ENTC",
    },
    {
      image: ruchira,
      name: "Ruchira Abeywardhane",
      designation: "3rd Year Undergraduate, ENTC",
    },
    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
    {
      image: rangaRodrigo,
      name: "Dr. Ranga Rodrigo",
      designation: "Senior Lecturer, ENTC, UoM",
    },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [
    {
      fact: "According to a report by Allied Market Research, the global market for IoT devices is projected to reach $1.1 trillion by 2026, driving demand for skilled embedded product designers. ",
      factBold: "",
      author: "-Allied Market Research-",
    },
    {
      fact: "Effective embedded product design is critical for the success of IoT devices, as it involves optimizing power consumption, minimizing latency, ensuring compatibility with various communication protocols and standards, and implementing robust security features to protect sensitive data and prevent cyberattacks.",
      factBold: "",
      author: "-Wiley-IEEE Press-",
    },
    {
      fact: "Embedded product design for IoT requires a multidisciplinary approach, combining expertise in electronics, software engineering, and mechanical design to create devices that are both functional and aesthetically pleasing.",
      factBold: "",
      author: "-IEEE-",
    },
  ],
  classSchedule: [
    "11 January 2025 (Concluded)",
    "26 January 2025",
    "02 February 2025",
    "09 February 2025",
    "16 February 2025",
    "23 February 2025",
    "09 March 2025",
    "23 March 2025",
    "30 March 2025",
    
  ],
  showSchedule:true,
  prerequisite: `
  Basic knowledge of microcontroller programming. Knowledge of
  topics covered in the 
  <a
    href="https://www.skillsurf.lk/microcontroller-based-embedded-system-design"
    target="_blank"
    rel="noreferrer"
  >
    Microcontroller-Based Embedded System Design course
  </a> will be a plus.
`,
  isCourseFlyer: true,
  flyer: {
    description:
      " Learn more about Embedded Product Design for IoT short course from the Course Outline Session flyer",
    button: "View Course Flyer",
    image: productDesignFlyer,
  },
  courseStatus: ECourseStatus.NEW,
  showTimer: false,
  learnMore: {
    description:
      "Learn more about Embedded Product Design for IoT and the importance of learning in general",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfEdu/embedded-product-design-for-iot-a4c90c496f7c",
  },
  timerDate: "2023-05-29 00:00",
  isDifferentBullet: true,
  // firstSessionFreeDetails: {
  //   instruction: {
  //     instructiveText:
  //       "The first session, which was free of charge to everyone, was held on 4 June 2023. The recording of the session is made available below.",
  //     instructiveTextSecond:
  //       "Only paid participants can attend from day 2 onwards. So, be sure to complete the payment by",
  //     date: "",
  //     dateSecond: "9 June 2023 (Friday) at 3 PM IST.",
  //     buttonText: "View Course Outline",
  //     buttonTextInfo: "View Slides",
  //     viewIntruction: "View Instructions",
  //     instructionVideoUrl: "_kld89c845w",
  //     viewIntructionLink:
  //       "https://docs.google.com/document/d/1l_uV3gx2zYOCQU7eyeFbxIyyTjWSUOgQ/edit?usp=sharing&ouid=114248016180290910181&rtpof=true&sd=true",
  //     buttonLink:
  //       "https://uniofmora-my.sharepoint.com/:b:/g/personal/scharles_uom_lk/EZty0I6Pr-ZMjUBN2673N54B1kn1RUTquYBOn4G498GNAA?e=3Gpx6K",
  //     buttonLinkInfo:
  //       "https://uniofmora-my.sharepoint.com/:b:/g/personal/scharles_uom_lk/EROvpVIyKKhCqwFXOhkdfP4B3rujd4IFJ1FsZJFUpYxlcQ?e=eNTwqp",
  //     moreInformation:
  //       "For any questions, please send a WhatsApp message to Mr. Damith Kandage at +94 70 405 9651",
  //   },
  // },

    firstSessionFreeDetails: {
    instruction: {
      isInstructive: true,
      instructiveText:
        "The first session, which was free of charge to everyone, was held on 11 January 2025. The recording of the session is made available below.",
      instructiveTextSecond:
        "Only paid participants can attend from day 2 onwards. So, please complete the payment by",
      date: "",
      dateSecond: "23rd January 2025.",
      buttonText: "View Course Outline",
      buttonTextInfo: "View Slides",
      viewIntruction: "",
      instructionVideoTopic:"Recording of the first sessions held on 11 January 2025",
      instructionVideoUrl: "DIc1AVH_nA8",
      videoTwoTopic:"",
      videoTwoUrl:"",
      viewIntructionLink:
        "",
      buttonLink:
        "",
      buttonLinkInfo:
        "https://drive.google.com/file/d/1fKKLHEYJ6JRkrJdU6aOVHQuQEgkRInVD/view",
      moreInformation:
        "For any questions, please send a WhatsApp message to Ms. Shehani Jayasinghe at +94 767 666 555.",
        slideButtonText:"Download Slides:",
        paymentInstructionButtonText:"here", 
        paymentInstructionButtonLink:"https://drive.google.com/file/d/163yWpV4tvrUMvMkdyq2-JHHDMyD9l0yd/view?usp=drive_link",
        paymentInstructionText:"Payment and other related instructions can be found",
        enrollmentFormLink:"https://forms.gle/3Pt7PzKFfvnbAU3A7",
        hardwareRequestFormLink:"https://forms.gle/cVg2TZVQSHhiej16A"
    },
  },  

  meetSection :{
    meetDetails:false,
    introPara: "The introductory session, which is free of charge to everyone, will be held on 11th January (Saturday) 2025 at 10.00 Am IST. This first session will give an overview of the course, the content covered, and the expected outcomes. Please use the Zoom link to join the session:",
    zoomLink:"https://learn.zoom.us/j/66480277252?pwd=STVxa2hlRi9SS0hGOU1RZlY0OTRwUT09",
    meetId:"664 8027 7252",
    passCode:"R?4xedWg",
    zoomLinkText:"Click here to join ",
    introSecondPara:"The introductory session and an email following the session will provide further instructions on how to make the payments and enroll in the course. See you on 11th!",
  },
};
